<template>
    <div @click.stop="paiement">
        <slot></slot>
    </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
import Api from '../../api/api';
import {mapGetters} from "vuex";
export default {
name: "PaiementStripe",
    components: {
    },
    data: () => ({
        stripe: null,
        session_id: null,
        chargement: false,
        urlGetSession: process.env.VUE_APP_API_URL + '/commande/sessionpaiement',
    }),
    props: {
        commande_id: {
            type: Number,
            required: true
        },
        libelle: {
            type: String,
            default: 'Paiement'
        },
        disabled: {
            type: Boolean,
            default:false
        }
    },
    computed: {
      ...mapGetters([
        'portail'
      ]),
    },
    methods: {
        async initStripe () {
           this.stripe = await loadStripe(this.portail.public_key,{
               apiVersion: '2020-08-27',
           });
           this.session_id = await Api.get(this.urlGetSession + '/' + this.commande_id);
        },
        paiement() {
            if (this.disabled || this.stripe === null || this.session_id === null) this.$emit('input');
            else {
                this.chargement = true
                //créé ou récupère la session Stripe dans le portail correspondant à cette commande
                this.stripe.redirectToCheckout({
                    sessionId: this.session_id
                }).catch(err => {
                    this.$store.commit('addSnackMessage', {msg: err})
                }).finally(() => {
                    this.chargement = false;
                });
            }
        }
    },
    mounted() {
        this.initStripe();
    }
}

</script>
<style scoped>

</style>