<template>
    <div class="commande" v-if="commande && ready">
        <div class="entete">
            <div class="fil-ariane">
                <router-link to="/">Accueil</router-link>
                <span> > </span>
                <router-link v-if="commande.statut_code === 4" to="/panier">Mon panier</router-link>
                <template v-else>
                    <router-link to="/compte">Mon compte</router-link>
                    <span> > </span>
                    <router-link to="/commandes">Historique de Commandes</router-link>
                </template>
                <span> > </span>
                <router-link :to="'/commande/'+ commande_id">Commande n°{{commande_id}}</router-link>
            </div>
            <h1 v-if="commande.statut_code === 4" class="titre">Paiement</h1>
            <h1 v-else-if="action==='succes'" class="titre">
                <v-icon x-large>mdiCheckboxMarkedOutline</v-icon>
                Merci pour votre commande !
            </h1>
            <h1 v-else class="titre">Détail de votre commande</h1>
        </div>
        <div v-if="commande.statut_code === 4" class="carte-arrondie">
            <fieldset class="recap">
                <legend>{{ commande.contenu.length + ' article'+ (commande.contenu.length>1?'s': '')}}</legend>
                <div class="montant">
                    <span class="phrase">Montant de votre commande </span>
                    <span class="valeur">{{ formatMonetaire(facturation.totalttc)}}</span>
                </div>
                <div class="cguv">
                    <v-form  ref="checkboxcguv">
                        <v-checkbox dense class="checkbox" v-model="cguv" :rules="rules.cguv" hide-details="auto">
                            <template v-slot:label=""><div style="display:none;"></div></template>
                        </v-checkbox>
                        <span>Je reconnais avoir lu et accepté l’ensemble des <router-link to="/condition-generales-de-vente-et-utilisation">conditions générales d’utilisation et de vente publiées sur le Site</router-link></span>
                    </v-form>
                </div>
            </fieldset>
            <div class="paiement">
                <span><b>Cliquez sur l'icône de paiement<!-- en bleu--></b> (vous allez être redirigé vers le site de paiement associé pour valider votre paiement)</span>

                <paiement-stripe :commande_id="commande_id" class="stripe elevation-4" :disabled="!cguv" @input="tickpaiement">
                    <picture class="service">
                        <source srcset="@/assets/img/footer/cb.webp" type="image/webp">
                        <source srcset="@/assets/img/footer/cb.png" type="image/jpeg">
                        <img src="@/assets/img/footer/cb.png" alt="Illustration carte bleu" decoding="async">
                    </picture>
<!--                    <img src="@/assets/img/footer/cb.png" decoding="async">-->
                    <label class="lienpaiement"><b>Paiement sécurisé par carte bancaire</b></label>
                </paiement-stripe>
            </div>
            <div class="mentions">
                Les informations recueillies par Imprime Facile (Groupe Ingénidoc) font l’objet d’un traitement destiné à la gestion
                de la relation client et aux opérations associées. Pour en savoir plus sur la gestion de vos données et de vos droits,
                <router-link to="/politique-de-confidentialite">cliquez ici</router-link>.
            </div>
        </div>
        <div v-else class="carte-arrondie detail">
            <h2 class="align-right">Votre numéro de commande : {{ commande_id }}</h2>
            <span v-if="action==='succes'">
                Toute l’équipe Imprime Facile vous remercie pour votre commande, {{ user.name }} !
                Vous allez bientôt recevoir un mail récapitulant toutes les infos de cette dernière sur {{ user.email }}.<br />
                En attendant, retrouvez toutes ces informations ci-dessous :
            </span>
            <h2>Détail de la commande</h2>
            <div>
                <span class="font-weight-bold">Date de la commande : </span>
                {{ commande.date_commande }}
            </div>
            <commande-infos class="gris elevation-2" :detail="false" :commande="commandeDetaillee"></commande-infos>

            <div class="infos">
                <div class="adresses">
                    <template v-if="livraisonEstFacturation">
                        <div class="livraison">
                            <div>Votre commande sera livrée et facturée à :</div>
                            <bloc-adresse v-model="commande.livraison"></bloc-adresse>
                        </div>
                    </template>
                    <template v-else>
                        <div class="livraison">
                            <div>Votre commande sera livrée à :</div>
                            <bloc-adresse v-model="commande.livraison"></bloc-adresse>
                        </div>

                        <div class="facturation">
                            <div>Votre commande sera facturée à :</div>
                            <bloc-adresse v-model="commande.facturation"></bloc-adresse>
                        </div>
                    </template>
                </div>
                <div class="contact center">
                    <h3>Besoin d'aide ?</h3>
                    <h4>Contactez notre service client au</h4>
                    <div class="enbleu">02.32.81.59.60</div>
                    <div class="horaires">
                        Ouvert du lundi au vendredi<br />
                        9h-12h30 / 14h-17h30
                    </div>
                    <h4>Ou par mail :</h4>
                    <a href="mailto:contact@ingenidoc.fr" class="enbleu">contact@ingenidoc.fr</a>
                </div>
            </div>
        </div>
        <v-dialog
            :width="450"
            v-model="message"
        >
            <v-card>
                <v-card-title>
                    {{ message }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="fermeMessage">Continuer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            top
            right
            :timeout="-1"
            :color="informationRequete.status"
            v-model="informationRequete.ouvert"
        >
            <div v-html="informationRequete.texte"></div>

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="fermeInformationRequete">Fermer</v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>

import Api from "../api/api";
import MonnaieMixin from "../mixins/monnaie";
import PaiementStripe from "../components/Stripe/PaiementStripe";
import StatutMixin, {
    NON_VALIDEE,
} from '../mixins/statuts';
import SuiviMixin from '../mixins/suivi';
import {mapGetters} from "vuex";
import CommandeInfos from "../components/CommandeInfos";
import BlocAdresse from "../components/adresse/BlocAdresse";
import { mdiCheckboxMarkedOutline } from "@mdi/js";

export default {
    name: "Commande",
    components: {
        PaiementStripe,
        CommandeInfos,
        BlocAdresse
    },
    mixins: [
        MonnaieMixin,
        SuiviMixin,
        StatutMixin,
    ],
    data: () => ({
        commande_id: null,
        urlInfosCommande      : process.env.VUE_APP_API_URL + '/commande/infos/id/',
        urlDetailsCommande    : process.env.VUE_APP_API_URL + '/commande/detaillee/',
        urlFacturationCommande: process.env.VUE_APP_API_URL + '/commande/facturation/id/',
        urlVerifiePaiement    : process.env.VUE_APP_API_URL + '/commande/verifiepaiement/',
        urlExpeditionsCommande: process.env.VUE_APP_API_URL + '/commande/envois/',
        commande         : null,
        commandeDetaillee: null,
        facturation      : null,
        envois           : [],
        ready            : false,
        message          : '',
        cguv             : false,
        action           : undefined,

        rules: {
            cguv: [value => !!value || "Requis"]
        },

        informationRequete: {
            text: '',
            status: '',
            ouvert: false
        },
        mdiCheckboxMarkedOutline
    }),
    computed: {
        ...mapGetters([
            'user',
            'portail'
        ]),
        validee () {
            return this.commande && NON_VALIDEE.indexOf(this.commande.statut_code) === -1;
        },
        payeEnCB () {
            if (!this.facturation || !this.facturation.reglee) return 0.0;
            return this.facturation.totalttc - this.facturation.bon_achat;
        },
        logistiqueEtConditionnement () {
            if (!this.facturation) return 0.0;
            return this.facturation.finitions + this.facturation.logistique + this.facturation.emballages;
        },
        livraisonEstFacturation() {
            return this.commande && this.commande.livraison && this.commande.facturation &&
                this.commande.livraison.societe === this.commande.facturation.societe &&
                this.commande.livraison.nom === this.commande.facturation.nom &&
                this.commande.livraison.prenom === this.commande.facturation.prenom &&
                this.commande.livraison.adresse1 === this.commande.facturation.adresse1 &&
                this.commande.livraison.adresse2 === this.commande.facturation.adresse2 &&
                this.commande.livraison.codepostal === this.commande.facturation.codepostal &&
                this.commande.livraison.ville === this.commande.facturation.ville &&
                this.commande.livraison.pays === this.commande.facturation.pays;
        }
    },
    methods: {
        tickpaiement() {
            this.$refs.checkboxcguv.validate()
        },
        fermeMessage() {
            this.message = ''
        },
        chargeCommande () {
            return Api.get(this.urlInfosCommande + this.commande_id)
                .then(commande => this.$set(this, 'commande', commande));
        },
        chargeCommandeDetaillee () {
            return Api.get(this.urlDetailsCommande + this.commande_id)
                .then(commande => this.$set(this, 'commandeDetaillee', commande));
        },
        chargeFacturation () {
            return Api.get(this.urlFacturationCommande + this.commande_id).then(facturation => {
                if (facturation) this.facturation = Object.assign({}, facturation);
            });
        },
        chargeExpeditions () {
            return Api.get(this.urlExpeditionsCommande + this.commande_id)
                .then(envois => {
                    this.envois = Object.assign([], envois);
                });
        },
        init () {
            return Promise.all([
                this.gereAction(),
                this.chargeCommande(),
                this.chargeCommandeDetaillee(),
                this.chargeFacturation(),
                this.chargeExpeditions()
            ]).catch(err => {
                this.ouvreInformationRequete(err.response.data.message, 'error')
            });
        },
        async gereAction () {
             this.action = this.$route.query.action;
             let session_id = this.$route.query.session_id,
                message = '';
            if (session_id !== undefined) {
                //la récupération de la session de paiement changera le statut si la commande est payée, dans ce cas la session de paiement est nulle
                let resultat = await Api.get(this.urlVerifiePaiement + session_id);
                if (resultat === true) message = "Merci de votre paiement, votre commande est maintenant prise en compte";
                if (this.action === 'annulation') message = 'Paiement annulé';
                if (this.action === 'remerciement') message = 'Merci de votre paiement, votre commande est maintenant validée';
            }

            if (session_id !== undefined) {
                await this.$router.push({query: {session_id: undefined, action: this.action}});
            }
            this.message = message;
            return Promise.resolve();
        },
        ouvreInformationRequete (texte, status) {
            this.informationRequete = Object.assign(this.informationRequete, { ouvert: true, texte, status });
        },
        fermeInformationRequete () {
            this.informationRequete.ouvert = false;
            this.$nextTick(() => {
                this.$router.go();
                this.$forceUpdate();
            })
        },
        retourSurListeOrigine () {
            //if (window.history.length > 2) this.$router.go(-1); Attention à gérer le cas du paiement qui faire un push vers la même route
            let origine = this.$route.query.origin;
            switch (origine) {
                case 'expeditions':
                    this.$router.push({ path: '/expeditions' });
                    break;
                case 'commandes':
                default:
                    this.$router.push({ path: '/commandes' });
                    break;
            }
        }
    },
    mounted () {
        this.commande_id = parseInt(this.$route.params.id);
        this.init().then(() => {
            this.ready = true
        })
    }
}

</script>

<style lang="scss">
.commande .carte-arrondie .recap .cguv .checkbox .v-input__slot {
    padding-left:4px;
}
</style>

<style scoped lang="scss">
.commande {
    width: 100%;
    height: 100%;
    position: relative;
    & .entete .titre {
        font: normal normal bold 35px/47px Nunito;
        color: black;
        & i {
            color: black;
            font-size: 64px !important;
        }
    }

    & .carte-arrondie {
        flex-direction:column;
        & .recap, .paiement {
            max-width:1000px;
            width:100%;
            margin: 30px auto 0 auto;
        }
        & .recap {
            padding:30px;
            & > * {
                text-align:center;
                margin: 0 auto;
            }
            & > legend {
                margin-left: auto;
                margin-right: auto;
                padding-left:24px;
                padding-right:24px;
                font: normal normal bold 20px/27px Nunito;
            }
            & > .cguv {
                margin-top:15px;
                & .checkbox {
                    display:inline-block;
                    width:38px;
                }
                & > .v-simple-checkbox {
                    display:inline-block;
                }
                & > span {
                    vertical-align: text-bottom;
                    user-select: none;
                    cursor:pointer;
                    font: normal normal normal 15px/20px Nunito;
                }
            }
            & .montant {
                & .phrase {
                    font: normal normal normal 15px/20px Nunito;
                }
                & .valeur {
                    font: normal normal bold 20px/27px Nunito;
                }
                margin: 0 auto;
            }
        }
        & .paiement {
            font: normal normal normal 15px/20px Nunito;
            & > .stripe {
                &.disabled, &.disabled > label {
                    background-color:#e0e0e0;
                    cursor:not-allowed;
                }
                & :not(.disabled), :not(.disabled)>label {
                    cursor:pointer;
                }
                border:1px solid grey;
                border-radius:12px;
                padding:12px;
                cursor:pointer;
                max-width:155px;
                margin:40px auto;
                &:hover:not(.disabled) {
                    background-color:#f0f0f0;
                }
                & > img {
                    margin: auto;
                    max-width:85px;
                    display:block;
                }
                & > label {
                    text-align:center;
                    font: normal normal normal 15px/20px Nunito;
                    color:#1976d2;
                    max-width:125px;
                }
            }
        }
        & .mentions {
            text-align:center;
            font: normal normal normal 10px/14px Nunito;
        }

        &.detail {
            padding: 16px 16px 24px 16px;
            span {
                font: normal normal normal 20px/27px Nunito;
            }
            .gris {
                background: #F5F5F5 0 0 no-repeat padding-box;
                border-radius:4px;
                padding:12px;
            }
            .infos {
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                .adresses {
                    max-width:550px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    & .livraison, .facturation{
                        font: normal normal normal 25px/34px Nunito;
                        margin: 12px;
                        padding: 8px 24px 8px;
                    }
                }
                .contact {
                    max-width:550px;
                    text-align:center;
                    margin:auto 0;
                    padding:20px;
                    & > h3 {
                        font: normal normal normal 30px/41px Nunito;
                        font-weight:normal;
                    }
                    & > h4 {
                        font: normal normal normal 25px/34px Nunito;
                    }
                    & > .enbleu {
                        font: normal normal normal 30px/41px Nunito;
                        color: #00D5FF;
                    }
                    & > .horaires {
                        font: normal normal normal 15px/20px Nunito;
                    }
                }
            }
        }
    }
}
</style>