export const SUIVI_INCONNU = 'INCONNU';
export const SUIVI_ERREUR = 'ERREUR';
export const SUIVI_NPAI = 'NPAI';
export const SUIVI_NON_RECLAME = 'NON_RECLAME';
export const SUIVI_RETOUR_DESTINATAIRE = 'RETOUR_DESTINATAIRE';
export const SUIVI_EN_SOUFFRANCE = 'EN_SOUFFRANCE';
export const SUIVI_A_RETIRER = 'A_RETIRER';
export const SUIVI_DISTRIBUE = 'DISTRIBUE';
export const SUIVI_LIVRE = 'LIVRE';
export const SUIVI_TRI_EFFECTUE = 'TRI_EFFECTUE';
export const SUIVI_PRIS_EN_CHARGE = 'PRIS_EN_CHARGE';
export const SUIVI_EN_LIVRAISON = 'EN_LIVRAISON';
export const SUIVI_LIVRAISON_CE_JOUR = 'LIVRAISON_CE_JOUR';
import { mdiAlertCircle, mdiUndo, mdiAlert, mdiOfficeBuildingOutline, mdiCheckCircle, mdiTruck, mdiHome, mdiHelpCircle } from "@mdi/js";

export default {
    methods: {
        styleSuivi(suivi) {
            switch (suivi) {
                case SUIVI_NPAI:
                case SUIVI_NON_RECLAME:
                    return {
                        icon: mdiAlertCircle,
                        color: 'error'
                    }
                case SUIVI_RETOUR_DESTINATAIRE:
                    return {
                        icon: mdiUndo,
                        color: 'error'
                    }
                case SUIVI_EN_SOUFFRANCE:
                    return {
                        icon: mdiAlert,
                        color: 'error'
                    }
                case SUIVI_A_RETIRER:
                    return {
                        icon: mdiOfficeBuildingOutline,
                        color: 'warning'
                    }
                case SUIVI_DISTRIBUE:
                case SUIVI_LIVRE:
                    return {
                        icon: mdiCheckCircle,
                        color: 'success'
                    }
                case SUIVI_TRI_EFFECTUE:
                case SUIVI_PRIS_EN_CHARGE:
                    return {
                        icon: mdiTruck,
                        color: ''
                    }
                case SUIVI_EN_LIVRAISON:
                    return {
                        icon: mdiTruck,
                        color: 'primary'
                    }
                case SUIVI_LIVRAISON_CE_JOUR:
                    return {
                        icon: mdiHome,
                        color: 'warning'
                    }
                default:
                    return {
                        icon: mdiHelpCircle,
                        color: 'warning'
                    }
            }
        }
    }
}