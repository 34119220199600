<template>
    <div>
        <div v-if="value.societe.length > 0">
            {{ value.societe }}
        </div>
        <div v-if="value.nom.length > 0">
            {{ value.nom }} {{ value.prenom }}
        </div>
        <div>
            {{ value.adresse1 }}
        </div>
        <div>
            {{ value.adresse2 }}
        </div>
        <div>
            {{ value.codepostal }} {{ value.ville }}
        </div>
        <div>
            {{ value.pays }}
        </div>
    </div>
</template>
<script>

    export default {
        name: 'BlocAdresse',
        props: {
            value: {
                type: Object,
                required: true
            }
        }
    }

</script>
<style lang="scss" scoped>

</style>